
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TypeInfo, Scope } from '@/types/common'
@Component
export default class TypeDialog extends Vue {
  @Prop() readonly classifyShow!: boolean
  private tableData: TypeInfo[] = []
  private loading = false
  private oldData: { [key: number]: string } = {}
  private isEdit = false

  created () {
    this.getSupplierTypeList()
  }

  // 关闭弹窗
  closeSupplierClassify () {
    this.$emit('closeSupplierClassify')
  }

  // 获取分类列表
  getSupplierTypeList () {
    this.loading = true
    this.oldData = {}
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'supplierType' }).then((res) => {
      this.tableData = res.supplierType || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    if (!this.isEdit) {
      this.tableData.push({
        dicValue: '',
        isInput: true
      })
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的分类')
    }
  }

  // 取消
  onCancel (scope: Scope) {
    if (scope.row.dicCode) {
      // 是否为编辑
      this.tableData[scope.$index].isInput = false
      this.tableData[scope.$index].dicValue = this.oldData[scope.$index] // 取消值不变
    } else {
      this.tableData.splice(scope.$index, 1)
    }
    this.isEdit = false
  }

  // 保存
  onSave (row: TypeInfo) {
    if (row.dicValue) {
      const info = row.dicId
        ? {
          dicId: row.dicId,
          dicValue: row.dicValue
        }
        : {
          dicValue: row.dicValue
        }

      const url = row.dicId
        ? this.$apis.common.updateDic
        : this.$apis.common.insertDic

      this.$axios.post(url, info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.getSupplierTypeList()
      })
    } else {
      this.$message({
        message: '请输入分类名称',
        type: 'warning'
      })
    }
    this.isEdit = false
  }

  // 编辑
  onEdit (scope: Scope) {
    if (!this.isEdit) {
      this.tableData[scope.$index] = {
        ...scope.row,
        isInput: true
      }
      this.oldData[scope.$index] = scope.row.dicValue
      this.$set(this.tableData, scope.$index, this.tableData[scope.$index]) // 重新渲染表格数据
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的分类')
    }
  }

  // 删除
  onDelete (dicId: string) {
    if (!this.isEdit) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading = true
          this.$axios
            .post(this.$apis.common.deleteDic, {
              dicId
            })
            .then(() => {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getSupplierTypeList()
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      this.$message.warning('请先保存正在编辑的分类')
    }
  }
}
