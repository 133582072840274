
import { Vue, Component } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import { SupplierList, SupplierData } from '../../types/supplier.d'
import { CooperateStatusName } from '@/utils/enum'
import { KeepAlive } from '@/utils/decorators'
import SupplierClassify from './SupplierClassify.vue'

@Component({
  name: 'Supplier',
  components: { SupplierClassify, ImportData }
})
@KeepAlive
export default class Supplier extends Vue {
  page = 1
  size = 10
  total = 0
  loading = false
  classifyShow = false
  tableData: SupplierData[] = []
  private supplierTypeList = []
  private isShowImport = false

  searchForm = {
    supplierName: null,
    supplierType: null,
    cooperateStatus: null
  }

  cooperateStatusName = CooperateStatusName

  created () {
    this.loadData()
    this.getSupplierTypeList()
  }

  loadData () {
    this.loading = true
    this.$axios.get<SupplierList>(this.$apis.supplier.listByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  deleteSupplier (supplierId: string) {
    this.$confirm('确认删除该供应商嘛？', '提示').then(() => {
      this.$axios.post(this.$apis.supplier.deleteSupplier, {
        supplierId
      }).then(() => {
        this.$message.success('供应商删除成功!')
        this.loadData()
      })
    })
  }

  // 获取供应商分类列表
  getSupplierTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'supplierType' }).then(res => {
      this.supplierTypeList = res.supplierType || []
    })
  }

  // 供应商分类
  closeSupplierClassify () {
    this.classifyShow = false
    this.loadData()
    this.getSupplierTypeList()
  }
}
